export const SHOW_PRODUCT_PENDING = "SHOW_PRODUCT_PENDING";
export const SHOW_PRODUCT_ERROR = "SHOW_PRODUCT_ERROR";
export const SHOW_PRODUCT_SUCCESS = "SHOW_PRODUCT_SUCCESS";
export const SHOW_MODIFIERS_SUCCESS = "SHOW_MODIFIERS_SUCCESS";
export const SAVE_CART = "SAVE_CART";
export const SHOW_ACTIVE_TAXES_SUCCESS = "SHOW_ACTIVE_TAXES_SUCCESS";
export const SHOW_ACTIVE_LATEST_DISCOUNT_SUCCESS = "SHOW_ACTIVE_LATEST_DISCOUNT_SUCCESS";
export const SHOW_OUTLETS_SUCCESS = "SHOW_OUTLETS_SUCCESS";
export const SHOW_SALESTYPE_SUCCESS = "SHOW_SALESTYPE_SUCCESS";
export const SET_SUMMARY_ORDER_CART = "SET_SUMMARY_ORDER_CART";
export const SHOW_PROFILE_SUCCESS = "SHOW_PROFILE_SUCCESS";
export const SHOW_SALES_SUCCESS = "SHOW_SALES_SUCCESS";
export const SHOW_SUBDISTRICS_SUCCESS = "SHOW_SUBDISTRICS_SUCCESS";
export const SHOW_SHIPMENT_COST_SUCCESS = "SHOW_SHIPMENT_COST_SUCCESS";
export const SAVE_CHECKOUT_DATA = "SAVE_CHECKOUT_DATA";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const SET_ONLINE_STORE_DATA = "SET_ONLINE_STORE_DATA";
export const SET_ONLINE_STORE_USER_ID = "SET_ONLINE_STORE_USER_ID";
export const SHOW_ORDER_SUCCESS = "SHOW_ORDER_SUCCESS";
export const SHOW_LOGISTICS_SUCCESS = "SHOW_LOGISTICS_SUCCESS";
export const SHOW_ONLINE_PAYMENTS_SUCCESS = "SHOW_ONLINE_PAYMENTS_SUCCESS";
export const SET_COUNTER_VALUE = "SET_COUNTER_VALUE";
